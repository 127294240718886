<template>
  <validation-observer ref="seoRules">
    <b-card title="SEO Ayarları">
      <title-text />
      <description-text />
      <keywords-text />
      <b-button
        variant="danger"
        @click="saveData"
      >
        <FeatherIcon icon="SaveIcon" />
        SEO Ayarlarını Kaydet
      </b-button>
    </b-card>
  </validation-observer>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import TitleText from '@/views/Admin/Seo_configs/elements/TitleText.vue'
import DescriptionText from '@/views/Admin/Seo_configs/elements/DescriptionText.vue'
import KeywordsText from '@/views/Admin/Seo_configs/elements/KeywordsText.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SeoForm',
  components: {
    ValidationObserver,
    TitleText,
    DescriptionText,
    KeywordsText,
    BCard,
    BButton,
  },
  props: {
    idComponents: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['seoConfigs/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('seoConfigs/getDataItem', {
        id_components: this.idComponents,
        item_id: this.itemId,
      })
    },
    saveData() {
      this.dataItem.id_components = this.idComponents
      this.dataItem.item_id = this.itemId
      this.$refs.seoRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('seoConfigs/saveData', this.dataItem)
            .then(response => {
              if (response.status) {
                this.showToast({
                  variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: response.message,
                })
                this.getData()
              } else {
                this.showToast({
                  variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: response.message,
                })
                this.getData()
              }
            })
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
