<template>
  <b-form-group
    label="Anahtar Kelimeler"
    label-for="meta_keywords"
  >
    <b-form-textarea
      id="meta_keywords"
      v-model="dataItem.keywords"
      placeholder="Anahtar Kelimeler"
    />
    <div class="text-muted">
      Anahtar Kelime: {{ dataItem.keywords? dataItem.keywords.length : 0 }} / 260
      <div class="font-small-2 text-info">
        Önerilen Uzunluk: 260 karakter
      </div>
    </div>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'DescriptionText',
  components: {
    BFormTextarea,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['seoConfigs/dataItem']
    },
  },
}
</script>
