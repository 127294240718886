<template>
  <div>
    <b-card>
      <b-row
        v-for="formSchema in formSchemas"
        :key="formSchema.id"
      >
        <b-col cols="12">
          <div class="font-weight-bolder text-primary font-medium-2 mb-2 border-bottom">
            {{ formSchema.title }}
          </div>
        </b-col>
        <b-col
          v-for="item in formSchema.items"
          :key="item.id"
          cols="12"
          md="4"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="dataItem.options"
              :value="item.id"
              multiple
            >
              {{ item.title }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'EstateOptions',
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['estates/dataItem']
    },
    formSchemas() {
      return this.$store.getters['estateConfigOptions/formSchemas']
    },
  },
  created() {
    this.getFormSchemas()
  },
  methods: {
    getFormSchemas() {
      this.$store.dispatch('estateConfigOptions/getFormSchemas')
    },
  },
}
</script>
