<template>
  <b-form-group
    label="İl"
    label-for="id_cities"
  >
    <validation-provider
      #default="{ errors }"
      name="İl"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_cities"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="İl"
        @input="getDistricts"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'CitySelect',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cities/dataList']
    },
    dataItem() {
      return this.$store.getters['estates/dataItem']
    },
  },
  created() {
    localize('tr')
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('cities/getDataList', {
        select: [
          'cities.id AS id',
          'cities.title AS title',
        ],
      })
    },
    getDistricts() {
      if (this.dataItem.id_cities) {
        this.dataItem.id_districts = null
        this.$store.dispatch('districts/getDataList', {
          select: [
            'districts.id AS id',
            'districts.title AS title',
          ],
          where: {
            'districts.id_cities': this.dataItem.id_cities,
          },
        })
      }
    },
  },
}
</script>
