<template>
  <b-form-group
    label="Açıklama"
    label-for="meta_desc"
  >
    <b-form-textarea
      id="meta_desc"
      v-model="dataItem.descriptions"
      placeholder="Açıklama"
    />
    <div class="text-muted">
      Açıklama Uzunluğu: {{ dataItem.descriptions? dataItem.descriptions.length : 0 }} / 155
      <div class="font-small-2 text-info">
        Önerilen Uzunluk: 155 karakter
      </div>
    </div>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'DescriptionText',
  components: {
    BFormTextarea,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['seoConfigs/dataItem']
    },
  },
}
</script>
