<template>
  <div>
    <b-card>
      <segments />
      <title-text />
      <summary-text />
      <content-text />
      <location-text />
      <delivery-methods />
      <e-mail-address />
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <city-select />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <district-select />
        </b-col>
        <b-col cols="12">
          <ordering-number />
        </b-col>
      </b-row>
      <options-check />
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Segments from '@/views/Admin/Estates/elements/Segments.vue'
import TitleText from '@/views/Admin/Estates/elements/Title.vue'
import SummaryText from '@/views/Admin/Estates/elements/Summary.vue'
import ContentText from '@/views/Admin/Estates/elements/Content.vue'
import LocationText from '@/views/Admin/Estates/elements/LocationText.vue'
import DeliveryMethods from '@/views/Admin/Estates/elements/DeliveryMethods.vue'
import EMailAddress from '@/views/Admin/Estates/elements/EMailAddress.vue'
import OptionsCheck from '@/views/Admin/Estates/elements/OptionsCheck.vue'
import CitySelect from '@/views/Admin/Estates/elements/CitySelect.vue'
import DistrictSelect from '@/views/Admin/Estates/elements/DistrictSelect.vue'
import OrderingNumber from '@/views/Admin/Estates/elements/OrderingNumber.vue'

export default {
  name: 'PublicForm',
  components: {
    BCard,
    BRow,
    BCol,
    Segments,
    TitleText,
    SummaryText,
    ContentText,
    LocationText,
    DeliveryMethods,
    EMailAddress,
    OptionsCheck,
    CitySelect,
    DistrictSelect,
    OrderingNumber,
  },
}
</script>
