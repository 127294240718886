<template>
  <validation-provider
    #default="{ errors }"
    name="Sekme Başlık"
    rules="required"
  >
    <b-form-group
      label="Sekme Başlık"
      label-for="seo_title"
    >
      <b-form-input
        id="seo_title"
        v-model="dataItem.title"
        placeholder="Sekme Başlık"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>
<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'TitleText',
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
  },
  computed: {
    dataItem() {
      return this.$store.getters['seoConfigs/dataItem']
    },
  },
}
</script>
