<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <public-form />
        <features-form v-if="dataItem.id" />
        <image-card />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>Harita Konumu</span>
        </template>
        <location-map />
      </b-tab>
      <b-tab :disabled="!dataItem.id">
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>Özellikler</span>
        </template>
        <estate-options />
      </b-tab>
      <b-tab :disabled="!dataItem.id">
        <template #title>
          <feather-icon icon="ImageIcon" />
          <span>Fotoğraf Galerisi</span>
        </template>
        <photo-gallery />
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="18"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import PublicForm from '@/views/Admin/Estates/elements/PublicForm.vue'
import FeaturesForm from '@/views/Admin/Estates/elements/FeaturesForm.vue'
import ImageCard from '@/views/Admin/Estates/elements/Image.vue'
import LocationMap from '@/views/Admin/Estates/elements/LocationMap.vue'
import PhotoGallery from '@/views/Admin/Estates/elements/PhotoGallery.vue'
import EstateOptions from '@/views/Admin/Estates/elements/EstateOptions.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    BTabs,
    BTab,
    PublicForm,
    FeaturesForm,
    ImageCard,
    LocationMap,
    PhotoGallery,
    EstateOptions,
    SeoForm,
  },
  computed: {
    dataItem() {
      return this.$store.getters['estates/dataItem']
    },
  },
}
</script>
