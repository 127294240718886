<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Fotoğraf Galerisi</b-card-title>
      <div>
        <b-button
          variant="primary"
          class="mr-1"
          @click="setModal('gallery')"
        >
          <FeatherIcon icon="UploadIcon" />
          Fotoğraf Yükle
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <div
        v-if="dataItem.images.length > 0"
      >
        <draggable
          v-model="images"
          tag="ul"
          class="cursor-move p-0 row match-height"
        >
          <div
            v-for="(image, index) in images"
            :key="index"
            tag="li"
            class="col-auto col-md-3"
          >
            <div
              class="border-light rounded p-1 mb-2"
              :class="image.id? 'bg-light-primary' : 'bg-light-warning'"
            >
              <div
                class="height-auto overflow-hidden"
                :class="image.id? 'bg-light-primary' : 'bg-light-warning'"
              >
                <b-img
                  :src="imageUrl + '/media/estates/' + image.thumb"
                  fluid
                  class="h-auto w-100"
                />
                <b-form-input
                  v-model="image.title"
                  size="sm"
                  class="mt-1"
                  placeholder="Görsel Açıklama"
                />
              </div>
              <div class="border-top mt-1 d-flex justify-content-around">
                <b-button
                  variant="outline-success"
                  size="sm"
                  pill
                  class="btn-icon mt-1"
                  :disabled="!image.id"
                  @click="saveImage(image)"
                >
                  <FeatherIcon icon="CheckIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  pill
                  class="btn-icon mt-1"
                  @click="removeImage(image,index)"
                >
                  <FeatherIcon icon="TrashIcon" />
                </b-button>
              </div>
            </div>
          </div>
        </draggable>
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <FeatherIcon icon="InfoIcon" /> Fotoğrafları sıralamak için sürükleyin.
          </div>
        </b-alert>
        <hr>
      </div>
    </b-card-body>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      :width-size="cropperSettings.widthSize"
      component="estates"
      :params="cropperSettings.type"
      :item-id="dataItem.id"
      @uploadImage="setUploadImage"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BImg, BButton, BAlert, BFormInput,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'PhotoGallery',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BImg,
    BButton,
    BFormInput,
    BAlert,
    draggable,
    CropperImage,
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      images: [],
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        widthSize: null,
        type: null,
      },
    }
  },
  computed: {
    dataItem() {
      const data = this.$store.getters['estates/dataItem']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.images = data.images
      }
      return data
    },
  },
  watch: {
    images() {
      this.$store.dispatch('estates/reOrderingImages', this.images)
    },
  },
  methods: {
    setModal(type) {
      if (type === 'gallery') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'estate-gallery-image.jpg',
          folder: 'estates',
          ratio: 4 / 3,
          widthSize: 1200,
          type: 'gallery',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      const image = {
        image: item.filename,
        thumb: item.thumb,
        image_webp: item.filename_webp,
        thumb_webp: item.thumb_webp,
        mediaData: item.thumb_webp,
      }
      this.cropperSettings.modalStatus = false
      this.dataItem.images.push(image)
      this.cropperSettings.modalStatus = false
    },
    saveImage(data) {
      this.$store.dispatch('estates/saveImage', data)
    },
    removeImage(data, index) {
      if (data.id) {
        this.$store.dispatch('estates/removeImageData', data.id)
          .then(response => {
            if (response) {
              this.dataItem.images.splice(index, 1)
            }
          })
      } else {
        this.dataItem.images.splice(index, 1)
      }
    },
  },
}
</script>
