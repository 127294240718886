<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-1"
    >
      <hr>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-form-checkbox
        v-model="dataItem.status"
        switch
        value="1"
      >
        Yayında
      </b-form-checkbox>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-form-checkbox
        v-model="dataItem.is_home"
        switch
        value="1"
      >
        Anasayfa'da Göster
      </b-form-checkbox>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-form-checkbox
        v-model="dataItem.is_sale"
        switch
        value="1"
      >
        Satılık
      </b-form-checkbox>
    </b-col>
    <b-col
      cols="12"
      md="auto"
    >
      <b-form-checkbox
        v-model="dataItem.is_rent"
        switch
        value="1"
      >
        Kiralık
      </b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'OptionsCheck',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  computed: {
    dataItem() {
      return this.$store.getters['estates/dataItem']
    },
  },
}
</script>
