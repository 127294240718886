<template>
  <b-form-group
    label="İlçe"
    label-for="id_districts"
  >
    <validation-provider
      #default="{ errors }"
      name="İlçe"
      rules="required"
    >
      <v-select
        v-model="dataItem.id_districts"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="İlçe"
        :disabled="dataList.length === 0"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'DistrictSelect',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['districts/dataList']
    },
    dataItem() {
      return this.$store.getters['estates/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
