<template>
  <div>
    <b-card>
      <template v-for="(formItem,index) in formSchemas">
        <b-form-group
          :key="index"
          :label="formItem.label"
          :label-for="formItem.id_estate_config_features + '_' + index"
        >
          <b-input-group :id="formItem.id_estate_config_features + '_' + index">
            <b-input-group-prepend
              v-if="formItem.prefix"
              is-text
            >
              {{ formItem.prefix }}
            </b-input-group-prepend>
            <b-form-input
              v-model="formItem.value"
              :placeholder="formItem.label"
            />
            <b-input-group-append
              v-if="formItem.suffix"
              is-text
            >
              {{ formItem.suffix }}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'PublicForm',
  components: {
    BCard,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
  },
  computed: {
    formSchemas() {
      return this.$store.getters['estateConfigFeatures/formSchemas']
    },
  },
}
</script>
